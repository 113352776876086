import { createOrderArray, httpRequest } from '../../../utils';
import { CreatePromotionClaimRequestBodyDto, DoAppPromotionClaimsIndexResponse } from './types';
import { doAppPromotionClaimsRequests } from './index';

export const indexRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppPromotionClaimsIndexResponse>(
    doAppPromotionClaimsRequests.index({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const createPromotionClaim = async (data: CreatePromotionClaimRequestBodyDto) => {
  return httpRequest.runRequest(
    doAppPromotionClaimsRequests.create({
      requestPayload: data
    })
  );
};
