import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppPromotion } from '../../../../../store/DoApp/Promotions/types';
import { doAppPromotionsOperations } from '../../../../../store/DoApp/Promotions';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetSearchInput } from '../../../../Shared/ListingWidget/WidgetSearchInput';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import { formatPublishDate } from '../../../../../utils';

type DoAppPromotionsListProps = {};

const DoAppPromotionsList: FunctionComponent<DoAppPromotionsListProps> = () => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [promotions, setPromotions] = useState<DoAppPromotion[]>([]);
  const [activePromotion, setActivePromotion] = useState<DoAppPromotion | null>(null);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const promotions = await doAppPromotionsOperations.indexRaw(page, 10, search, {});
      setPromotions(promotions.data);
      setPagination(promotions.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const renderList = () => {
    return (
      <>
        <WidgetTitle title={t('pages.doApp.promotions.title')} subtitle={t('pages.doApp.promotions.subtitle')} />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={fetchData}
        />

        {fetchError && <WidgetFetchError title={t('pages.doApp.promotions.fetchError')} />}
        <List>
          {promotions.map((promotion) => (
            <React.Fragment key={promotion.id}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={promotion.name}
                  secondary={
                    <div>
                      <div>{`${formatPublishDate(promotion.startDate)} - ${formatPublishDate(promotion.endDate)}`}</div>
                      <div>{promotion.id}</div>
                    </div>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>
      </>
    );
  };

  return renderList();
};

export default DoAppPromotionsList;
