import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import { CreateCreditTransactionRequestDto, IndexDoAppCreditTransactionsRequestParams } from './types';

export const index = (settings: RequestSettings<IndexDoAppCreditTransactionsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/credit-transactions?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, CreateCreditTransactionRequestDto>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/do-app/v1/credit-transactions'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});
