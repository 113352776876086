import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import { DoAppInvoiceDateRange, IndexDoAppMerchantsRequestParams } from './types';

export const index = (settings: RequestSettings<IndexDoAppMerchantsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/merchants?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const getInvoiceResources = (
  settings: RequestSettings<{ id: string; range: DoAppInvoiceDateRange }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/merchants/:id/invoice-resources?range=:range`
  },
  params: settings.requestParams
});

export const createInvoice = (
  settings: RequestSettings<{ id: string; range: DoAppInvoiceDateRange }, {}>
): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/merchants/:id/create-invoice?range=:range`
  },
  params: settings.requestParams
});
