import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import { CreatePromotionClaimRequestBodyDto, IndexDoAppPromotionClaimsRequestParams } from './types';

export const index = (settings: RequestSettings<IndexDoAppPromotionClaimsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/promotions/claims?per=:per&page=:page`
  },
  params: settings.requestParams
});

export const create = (settings: RequestSettings<{}, CreatePromotionClaimRequestBodyDto>): BaseSettings => ({
  query: {
    method: 'post',
    url: '/do-app/v1/promotions/claims'
  },
  params: settings.requestParams,
  payload: settings.requestPayload
});
