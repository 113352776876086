import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Loader from '../../../../Shared/Loading/Loader';
import { DoAppMerchant } from '../../../../../store/DoApp/Merchants/types';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import { WidgetTitle } from '../../../../Shared/ListingWidget/WidgetTitle';
import { WidgetSearchInput } from '../../../../Shared/ListingWidget/WidgetSearchInput';
import { WidgetPagination } from '../../../../Shared/ListingWidget/WidgetPagination';
import { WidgetFetchError } from '../../../../Shared/ListingWidget/WidgetFetchError';
import useOpenHandler from '../../../../../hooks/useOpenHandler';
import DoAppInvoicePreviewWindow from '../windows/DoAppInvoicePreview';

type DoAppMerchantsListProps = {};

const DoAppMerchantsList: FunctionComponent<DoAppMerchantsListProps> = () => {
  const { t } = useTranslation();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearchValue, setLastSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [merchants, setMerchants] = useState<DoAppMerchant[]>([]);
  const [activeMerchant, setActiveMerchant] = useState<DoAppMerchant | null>(null);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, per: 10, q: '', total: 0, totalPages: 0 });
  const [generateInvoiceWindowOpen, onGenerateInvoiceWindowOpen, onGenerateInvoiceWindowClose] = useOpenHandler();

  const fetchData = async (page: number, search: string) => {
    setFetchError(false);
    try {
      const merchants = await doAppMerchantsOperations.indexRaw(page, 10, search, {});
      setMerchants(merchants.data);
      setPagination(merchants.pagination);
      setLastSearchValue(search);
    } catch (e) {
      setFetchError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1, '');
  }, []);

  useEffect(() => {
    const onEnter = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(1, searchValue);
      }
    };
    document.addEventListener('keydown', onEnter);
    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [searchValue]);

  const renderList = () => {
    return (
      <>
        <WidgetTitle title={t('pages.doApp.merchants.title')} subtitle={t('pages.doApp.merchants.subtitle')} />
        <hr style={{ marginBottom: '1.0rem', opacity: 0.1 }} />
        {loading && <Loader />}
        <WidgetSearchInput
          placeholder={`${t('common.search')}...`}
          value={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={fetchData}
        />

        {fetchError && <WidgetFetchError title={t('pages.doApp.merchants.fetchError')} />}
        <List>
          {merchants.map((merchant) => (
            <React.Fragment key={merchant.id}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={merchant.name}
                  secondary={
                    <div>
                      <div>{merchant.billingEmail}</div>
                      <div>{merchant.id}</div>
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    onClick={async () => {
                      setActiveMerchant(merchant);
                      onGenerateInvoiceWindowOpen();
                    }}
                  >
                    {t('pages.doApp.merchants.generateInvoice')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pagination.total > 0 && (
            <WidgetPagination
              pagination={{ page: pagination.page, totalPages: pagination.totalPages }}
              handlePaginationChange={(val) => fetchData(val, searchValue)}
            />
          )}
        </div>
        <DoAppInvoicePreviewWindow
          open={generateInvoiceWindowOpen}
          merchant={activeMerchant}
          onCloseClick={onGenerateInvoiceWindowClose}
          fullScreenOnMobile
        />
      </>
    );
  };

  return renderList();
};

export default DoAppMerchantsList;
