import moment from 'moment';
import { keyBy } from 'lodash';
import { AdminUser } from './AdminUsers/types';
import { Subscriber } from './Subscribers/types';
import { SystemSetting, SystemSettingsNormalized, SystemSettingType } from './SystemSettings/types';
import { AdminKeyword, AdminKeywordGroup } from './AdminKeywords/types';
import { AdminPasswordPolicy } from './AdminPasswordPolicies/types';
import { AdminRole } from './AdminRoles/types';
import { PrintProject } from './PrintProjects/types';
import { AdminPrintTemplate } from './AdminPrintTemplates/types';
import { AdminEmailTemplate } from './AdminEmailTemplates/types';
import { MailingList } from './MailingLists/types';
import { AdminOrganization } from './AdminOrganizations/types';
import { AdminOrganizationUser } from './AdminOrganizationUsers/types';
import { MyOrganizationUser } from './MyOrganization/types';
import { AnyProject } from './Dashboard/types';
import { AdminOnlineUser } from './AdminOnlineUsers/types';
import { AdminMailingList } from './AdminMailingLists/types';
import { Story, StoryContentContributor } from './Stories/types';
import { SharedProject } from './SharingCenter/types';
import { DesignProject } from './DesignProjects/types';
import { Campaign } from './Campaigns/types';
import { DoAppMerchant } from './DoApp/Merchants/types';

export function normalizeAdminUsers(adminUsers: AdminUser[]) {
  return {
    entities: keyBy(adminUsers, 'id'),
    result: adminUsers.map(({ id }) => id)
  };
}

export function normalizeOnlineUsers(onlineUser: AdminOnlineUser[]) {
  return {
    entities: keyBy(onlineUser, 'id'),
    result: onlineUser.map(({ id }) => id)
  };
}

export function normalizeMailingLists(mailingLists: MailingList[]) {
  return {
    entities: keyBy(mailingLists, 'id'),
    result: mailingLists.map(({ id }) => id)
  };
}

export function normalizeAdminMailingLists(adminMailingLists: AdminMailingList[]) {
  return {
    entities: keyBy(adminMailingLists, 'id'),
    result: adminMailingLists.map(({ id }) => id)
  };
}

export function normalizeOrganizations(organizations: AdminOrganization[]) {
  return {
    entities: keyBy(organizations, 'id'),
    result: organizations.map(({ id }) => id)
  };
}

export function normalizeOrganizationUsers(organizationUsers: AdminOrganizationUser[]) {
  return {
    entities: keyBy(organizationUsers, 'id'),
    result: organizationUsers.map(({ id }) => id)
  };
}

export function normalizeMyOrganizationUsers(organizationUsers: MyOrganizationUser[]) {
  return {
    entities: keyBy(organizationUsers, 'id'),
    result: organizationUsers.map(({ id }) => id)
  };
}

export function normalizeKeywords(keyword: AdminKeyword[]) {
  return {
    entities: keyBy(keyword, 'id'),
    result: keyword.map(({ id }) => id)
  };
}

export function normalizeKeywordGroups(keywordGroup: AdminKeywordGroup[]) {
  return {
    entities: keyBy(keywordGroup, 'id'),
    result: keywordGroup.map(({ id }) => id)
  };
}

export function normalizePasswordPolicies(passwordPolicy: AdminPasswordPolicy[]) {
  return {
    entities: keyBy(passwordPolicy, 'id'),
    result: passwordPolicy.map(({ id }) => id)
  };
}

export function normalizeRoles(role: AdminRole[]) {
  return {
    entities: keyBy(role, 'id'),
    result: role.map(({ id }) => id)
  };
}

export function normalizeProjects(projects: AnyProject[]) {
  return {
    entities: keyBy(projects, 'id'),
    result: projects.map(({ id }) => id)
  };
}

export function normalizeSharedProjects(projects: SharedProject[]) {
  return {
    entities: keyBy(projects, 'id'),
    result: projects.map(({ id }) => id)
  };
}

export function normalizePrintProjects(projects: PrintProject[]) {
  return {
    entities: keyBy(projects, 'id'),
    result: projects.map(({ id }) => id)
  };
}

export function normalizeStories(stories: Story[]) {
  return {
    entities: keyBy(stories, 'id'),
    result: stories.map(({ id }) => id)
  };
}

export function normalizeStoryContributors(contributors: StoryContentContributor[]) {
  return {
    entities: keyBy(contributors, 'id'),
    result: contributors.map(({ id }) => id)
  };
}

export function normalizePrintTemplates(templates: AdminPrintTemplate[]) {
  return {
    entities: keyBy(templates, 'id'),
    result: templates.map(({ id }) => id)
  };
}

export function normalizeEmailTemplates(templates: AdminEmailTemplate[]) {
  return {
    entities: keyBy(templates, 'id'),
    result: templates.map(({ id }) => id)
  };
}

export function normalizeSubscribers(subscribers: Subscriber[]) {
  return {
    entities: keyBy(subscribers, 'id'),
    result: subscribers.map(({ id }) => id)
  };
}

export function normalizeDesignProjects(project: DesignProject[]) {
  return {
    entities: keyBy(project, 'id'),
    result: project.map(({ id }) => id)
  };
}

export function normalizeSystemSettings(systemSettings: SystemSetting[]): SystemSettingsNormalized {
  return systemSettings.reduce((ob, curr) => {
    let value = curr.value;

    if (curr.type === SystemSettingType.PUBLISH_HOUR) {
      value = Math.floor(parseInt(value) + moment().utcOffset() / 60).toString();
    }

    ob[curr.type] = value;

    return ob;
  }, {} as SystemSettingsNormalized);
}

export function normalizeCampaigns(campaigns: Campaign[]) {
  return {
    entities: keyBy(campaigns, 'id'),
    result: campaigns.map(({ id }) => id)
  };
}

export function normalizeDoAppMerchants(merchants: DoAppMerchant[]) {
  return {
    entities: keyBy(merchants, 'id'),
    result: merchants.map(({ id }) => id)
  };
}
