import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DoAppInvoiceDateRange, DoAppMerchant } from '../../../../../store/DoApp/Merchants/types';
import { doAppMerchantsOperations } from '../../../../../store/DoApp/Merchants';
import ModalConfirmationWindow from '../../../../Shared/Window/ModalConfirmationWindow';
import { Row, Strong } from '../../../../Shared/StyledComponents';
import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import Toast from '../../../../Shared/Toast/Toast';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type DoAppInvoicePreviewWindowProps = {
  open: boolean;
  merchant: DoAppMerchant | null;
  onCloseClick: () => void;
  onFormSubmit?: () => void;
  fullScreenOnMobile: boolean;
};

export type PaymentProcessorChargeEntry = { amount: number; description: string; date_from?: number; date_to?: number };

type InvoiceData = {
  charges: PaymentProcessorChargeEntry[];
  refund: number;
};

const DoAppInvoicePreviewWindow: FunctionComponent<DoAppInvoicePreviewWindowProps> = ({
  merchant,
  onCloseClick,
  open
}) => {
  const { t } = useTranslation();
  const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<DoAppInvoiceDateRange>(DoAppInvoiceDateRange.THIS_MONTH);
  const [customDateFrom, setCustomDateFrom] = useState<Date | null>(null);
  const [customDateTo, setCustomDateTo] = useState<Date | null>(null);

  useEffect(() => {
    if (open && merchant) {
      fetchInvoiceData(dateRange);
    }
  }, [open, dateRange]);

  const fetchInvoiceData = async (dateRange: DoAppInvoiceDateRange) => {
    if (!merchant) return;

    try {
      const res = await doAppMerchantsOperations.getInvoiceResources(merchant.id, dateRange);
      const invoice = res.data;
      setInvoiceData(invoice);
      calculateTotal(invoice);
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    }
  };

  const createInvoice = async (dateRange: DoAppInvoiceDateRange) => {
    if (!merchant) return;

    try {
      const res = await doAppMerchantsOperations.createInvoice(merchant.id, dateRange);
      const invoiceId = res.data;
      console.log('invoiceId', invoiceId);
    } catch (e) {
      Toast.error(t('notifications.common.errorOperation'));
    }
  };

  const handleSubmit = () => {
    createInvoice(dateRange);
  };

  const calculateTotal = (invoice: InvoiceData) => {
    const totalCharges = invoice.charges.reduce((sum, charge) => sum + charge.amount, 0);
    const totalValue = totalCharges - invoice.refund;
    if (totalValue <= 0) {
      setTotal(0);
    } else {
      setTotal(totalValue / 100);
    }
  };

  const formatDate = (timestamp?: number) => {
    if (!timestamp) return '-';
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US');
  };

  return (
    <ModalConfirmationWindow
      open={open}
      header={t('pages.doApp.merchants.windows.generateInvoice.header')}
      hideActionButtons
      onCloseClick={onCloseClick}
      fullWidth
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={2}>
        <div>
          <FormControl variant="outlined" size="small">
            <Select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value as DoAppInvoiceDateRange)}
              variant="outlined"
            >
              <MenuItem value={DoAppInvoiceDateRange.THIS_MONTH}>
                {t('pages.doApp.merchants.windows.generateInvoice.thisMonth')}
              </MenuItem>
              <MenuItem value={DoAppInvoiceDateRange.LAST_MONTH}>
                {t('pages.doApp.merchants.windows.generateInvoice.lastMonth')}
              </MenuItem>
              <MenuItem value={DoAppInvoiceDateRange.CUSTOM}>
                {t('pages.doApp.merchants.windows.generateInvoice.customDateRange')}
              </MenuItem>
            </Select>
          </FormControl>
          {dateRange === DoAppInvoiceDateRange.CUSTOM && (
            <Box display="flex" alignItems="center" mt={2}>
              <DatePicker
                selected={customDateFrom}
                onChange={(date) => setCustomDateFrom(date)}
                selectsStart
                startDate={customDateFrom}
                endDate={customDateTo}
                customInput={
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t('pages.doApp.merchants.windows.generateInvoice.selectDateFrom')}
                    size="small"
                  />
                }
              />
              &nbsp;
              <DatePicker
                selected={customDateTo}
                onChange={(date) => setCustomDateTo(date)}
                selectsEnd
                startDate={customDateFrom}
                endDate={customDateTo}
                customInput={
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t('pages.doApp.merchants.windows.generateInvoice.selectDateTo')}
                    size="small"
                  />
                }
              />
            </Box>
          )}
        </div>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {t('pages.doApp.merchants.windows.generateInvoice.generate')}
        </Button>
      </Box>
      <Divider />
      {invoiceData ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>{t('pages.doApp.merchants.windows.invoice.description')}</strong>
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <strong>{t('pages.doApp.merchants.windows.invoice.amount')}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceData.charges.map((charge, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body2">{charge.description}</Typography>
                  {charge.date_from && (
                    <Typography variant="caption">
                      <strong>{t('pages.doApp.merchants.windows.invoice.date')}</strong>:{' '}
                      <em>
                        {formatDate(charge.date_from)} - {formatDate(charge.date_to)}
                      </em>
                    </Typography>
                  )}
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                  ${(charge.amount / 100).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
            {invoiceData.refund > 0 && (
              <TableRow>
                <TableCell>
                  <Strong>{t('pages.doApp.merchants.windows.invoice.refund')}</Strong>
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                  -${(invoiceData.refund / 100).toFixed(2)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Strong>{t('pages.doApp.merchants.windows.invoice.total')}</Strong>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                <Strong>${total.toFixed(2)}</Strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <Row>{t('pages.doApp.merchants.windows.invoice.loading')}</Row>
      )}
    </ModalConfirmationWindow>
  );
};

export default DoAppInvoicePreviewWindow;
