export enum PaginationStoreModule {
  DASHBOARD_PROJECTS = '/dashboard',
  STORY = '/story',
  STORY_CONTENT_CONTRIBUTORS = '/story_contributors',
  PRINT_PROJECTS = '/print_projects',
  EMAIL_PROJECTS = '/email_projects',
  ADMIN_USERS = '/users',
  SUBSCRIBERS = '/subscribers',
  ADMIN_ROLES = '/roles_administration',
  ADMIN_PRINT_TEMPLATES = '/print_templates_administration',
  ADMIN_EMAIL_TEMPLATES = '/email_templates_administration',
  ADMIN_KEYWORDS = '/keywords_administration',
  ADMIN_ONLINE_USERS = '/online_users',
  ADMIN_KEYWORD_GROUPS = '/keyword_groups_administration',
  ADMIN_PASSWORD_POLICIES = '/password_policies_administration',
  ADMIN_ORGANIZATIONS = '/organizations_administration',
  ADMIN_ORGANIZATION_USERS = '/organization_users',
  MY_ORGANIZATION_USERS = '/my_organization',
  MAILING_LISTS = '/mailing_lists',
  ADMIN_MAILING_LISTS = '/mailing_lists_dashboard',
  DESIGN_PROJECTS = '/design_projects',
  ADMIN_PROJECTS = '/projects_administration',
  SHARING_CENTER = '/sharing_center',
  CAMPAIGNS = '/campaigns',
  DO_APP_MERCHANTS = '/doapp/merchants'
}

export type ChangePageAction = {
  type: PaginationActionsConstants.CHANGE_PAGE;
  module: PaginationStoreModule;
  payload: ChangePageActionPayload;
};

export type ChangePageActionPayload = {
  page: number;
};

export type ChangePerAction = {
  type: PaginationActionsConstants.CHANGE_PER;
  module: PaginationStoreModule;
  payload: ChangePerActionPayload;
};

export type ChangePerActionPayload = {
  per: number;
};

export type ChangeSearchQueryAction = {
  type: PaginationActionsConstants.CHANGE_SEARCH_QUERY;
  module: PaginationStoreModule;
  payload: ChangeSearchQueryActionPayload;
};

export type ChangeSearchQueryActionPayload = {
  q: string | null;
};

export type ChangePaginationActionPayload = {
  page: number;
  per: number;
  q: string | null;
};

export type ChangePaginationAction = {
  type: PaginationActionsConstants.CHANGE_PAGINATION;
  module: PaginationStoreModule;
  payload: ChangePaginationActionPayload;
};

export type ClearPaginationAction = {
  type: PaginationActionsConstants.CLEAR_ALL;
  module: PaginationStoreModule;
};

export enum PaginationActionsConstants {
  CHANGE_SEARCH_QUERY = 'PAGINATION/CHANGE_SEARCH_QUERY',
  CHANGE_PAGE = 'PAGINATION/CHANGE_PAGE',
  CHANGE_PER = 'PAGINATION/CHANGE_PER',
  CHANGE_PAGINATION = 'PAGINATION/CHANGE_PAGINATION',
  CLEAR_ALL = 'PAGINATION/CLEAR_ALL'
}

export type PaginationActionType =
  | ChangePageAction
  | ChangePerAction
  | ClearPaginationAction
  | ChangePaginationAction
  | ChangeSearchQueryAction;
