import { createOrderArray, httpRequest } from '../../../utils';
import { DoAppPromotionsIndexResponse } from './types';
import { doAppPromotionsRequests } from './index';

export const indexRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppPromotionsIndexResponse>(
    doAppPromotionsRequests.index({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};
