import { createOrderArray, httpRequest } from '../../../utils';
import { CreateCreditTransactionRequestDto, DoAppCreditTransactionsIndexResponse } from './types';
import { doAppCreditTransactionsRequests } from './index';

export const indexRaw = (page: number, per: number, q: string | null, order: any) => {
  return httpRequest.runRequest<DoAppCreditTransactionsIndexResponse>(
    doAppCreditTransactionsRequests.index({
      requestParams: {
        page: page,
        per: per,
        q: q,
        order: createOrderArray(order)
      }
    })
  );
};

export const createTransaction = async (data: CreateCreditTransactionRequestDto) => {
  return httpRequest.runRequest(
    doAppCreditTransactionsRequests.create({
      requestPayload: data
    })
  );
};
