import { BaseSettings, RequestSettings } from '../../../utils/httpRequest';
import { IndexDoAppPromotionsRequestParams } from './types';

export const index = (settings: RequestSettings<IndexDoAppPromotionsRequestParams, {}>): BaseSettings => ({
  query: {
    method: 'get',
    url: `/do-app/v1/promotions?per=:per&page=:page`
  },
  params: settings.requestParams
});
